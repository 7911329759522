<template>
  <div :class="$style[`designer-carousel`]">
    <div :class="$style[`title`]" >
      <h2>Find out why Clippng is trusted by users</h2>
    </div>
    <div :class="$style[`carousel`]">
      <div :class="$style.center">
        <div :class="$style.clipper">
          <carousel
              :items-to-show="data.carousel.itemToShow"
              :items-to-scroll="1"
              :wrap-around="true"
              :autoplay="0"
              :ignore-animations="true"
              :mouse-drag="false"
              :touch-drag="false"
              v-model="data.carousel.index"
          >
            <slide v-for="( item, index ) in data.dataList" :key="index">
              <designer-item :item="item" :class="$style[`item`]" />
            </slide>

            <!--          <template #addons>-->
            <!--              <navigation />-->
            <!--              <pagination />-->
            <!--          </template>-->
          </carousel>
          <div :class="$style[`pagination`]" v-show="data.dataList.length > 3">
            <div :class="[$style[`pagination-item`], index===navIndex && $style.active]"
                 v-for="(item, index) in navBtns"
                 @click="handleClickNav(item)"
            >
              <div :class="$style.i"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import {reactive} from "vue";
import DesignerItem from "@components/home/designer-carousel/designer-item.vue";

onMounted(()=>{

})
/* ******************  变量 ***************************/
const data = reactive({
  carousel: {
    index: 0,
    itemToShow: 3,
    enabled: false
  },
  dataList: [
    {
      text: "I'd highly recommend checking out Clippng for PNG assets. Clippng offers a vast collection of high-resolution PNG images across a multitude of themes and categories, making it a perfect fit for design projects. The best part? The assets are not only free but also royalty-free, allowing for commercial use without any worries. Plus, the quality of images on Clippng is top-notch, ensuring that they meet the standards of professional designers.",
      company: 'Intermediate graphic designer',
      name: 'Anthony',
      portrait: 'https://dz-img.bigbigwork.cn/3w/tupu/home/avatar1.png'
    },
    {
      text: "Clippng offers a vast collection of design resources, including UI components and icons, all of which are readily available for use in design projects. The platform’s search functionalities make it easy to locate the exact assets you need and enhances productivity.",
      company: 'UI designer',
      name: 'Patricia',
      portrait: 'https://dz-img.bigbigwork.cn/3w/tupu/home/avatar2.png'
    },
    {
      text: "Clippng is a PNG stock images market. The staff are nice and reply in time. The Graphics are good enough and you can almost find anything you looking for.",
      company: 'Senior designer + Illustrator',
      name: 'Dorothy',
      portrait: 'https://dz-img.bigbigwork.cn/3w/tupu/home/avatar3.png'
    }
  ],
});

/* *************************** 方法 ****************************/
// 点击导航
const handleClickNav = ( item ) => {
  data.carousel.index = item.startIndex;
}

/* *************************** 计算属性 ****************************/
// 导航当前索引
const navIndex = computed( ()=>{
  return Math.floor( data.carousel.index / data.carousel.itemToShow );
})
// 导航按钮信息
const navBtns = computed( ()=>{
  const btns = [];
  let index = 0;
  const step = data.carousel.itemToShow;
  for ( let i =0; i< data.dataList.length; i+=step ) {
    btns.push ({
      startIndex: i,
    })
    index = i;
  }
  if ( index < data.dataList.length - 1 ) {
    btns.push ({
      startIndex: index + step,
    })
  }
  return btns;
})
</script>
<style lang="scss" module src="./designer-carousel.scss"></style>