<template>
  <div :class="$style[`designer-item`]">
    <div :class="$style['center-box']">
      <div :class="$style['inner-box']">
        <div :class="$style['text-wrapper']">
          <img :class="$style['bg1']" :src="`${process.env.CDN_CP_PATH}/img/icon/quot_1.svg`" alt="">
          <img :class="$style['bg2']" :src="`${process.env.CDN_CP_PATH}/img/icon/quot_2.svg`" alt="">
          <p v-html="props.item.text" :class="$style['text']"></p>
        </div>
        <img :class="$style['score']" :src="`${process.env.CDN_CP_PATH}/img/icon/stars-five.svg`" alt="score 5.0"/>
        <span :class="$style['name']">{{ props.item.name }}</span>
        <span :class="$style['company']">{{ props.item.company }}</span>
        <div :class="$style['portrait']"><img :src="props.item.portrait" alt=""/></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
export type TDesignerItem = {
  name: string,
  company: string,
  text: string,
  portrait: string,
}
const props = defineProps<{
  item: TDesignerItem
}>()
</script>
<style lang="scss" module src="./designer-item.scss"></style>